@use '../scss/abstracts' as *;

.left-edge-img-wrap {
  margin-left: 0px;

  @include breakpoint-up(lg) {
    margin-left: calc((100vw - (#{$lg + 1}px - 64px)) / 2 * -1);
    @media (min-width: calc(#{$lg + 100}px)) {
      margin-left: calc((100vw - (#{$lg + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(xl) {
    margin-left: calc((100vw - (#{$xl + 1}px - 64px)) / 2 * -1);
    @media (min-width: calc(#{$xl + 100}px)) {
      margin-left: calc((100vw - (#{$xl + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(xxl) {
    margin-left: calc((100vw - (#{$xxl + 1}px - 64px)) / 2 * -1);
    @media (min-width: calc(#{$xxl + 100}px)) {
      margin-left: calc((100vw - (#{$xxl + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(xxxl) {
    margin-left: calc((100vw - (#{$xxxl + 1}px - 64px)) / 2 * -1);
    @media (min-width: calc(#{$xxxl + 100}px)) {
      margin-left: calc((100vw - (#{$xxxl + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(fhd) {
    margin-left: calc((100vw - (#{$fhd + 1}px - 96px)) / 2 * -1);
    @media (min-width: calc(#{$fhd + 100}px)) {
      margin-left: calc((100vw - (#{$fhd + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(qhd) {
    margin-left: calc((100vw - (#{$qhd + 1}px - 96px)) / 2 * -1);
    @media (min-width: calc(#{$qhd + 100}px)) {
      margin-left: calc((100vw - (#{$qhd + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(kkk) {
    margin-left: calc((100vw - (#{$kkk + 1}px - 128px)) / 2 * -1);
    @media (min-width: calc(#{$kkk + 100}px)) {
      margin-left: calc((100vw - (#{$kkk + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(uwqhd) {
    margin-left: calc((100vw - (#{$uwqhd + 1}px - 128px)) / 2 * -1);
    @media (min-width: calc(#{$uwqhd + 100}px)) {
      margin-left: calc((100vw - (#{$uwqhd + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(kkkk) {
    margin-left: calc((100vw - (#{$kkkk + 1}px - 128px)) / 2 * -1);
    @media (min-width: calc(#{$kkkk + 100}px)) {
      margin-left: calc((100vw - (#{$kkkk + 1}px - 35px)) / 2 * -1);
    }
  }
}

.right-edge-img-wrap {
  margin-right: 0px;

  @include breakpoint-up(lg) {
    margin-right: calc((100vw - (#{$lg + 1}px - 53px)) / 2 * -1);
    @media (min-width: calc(#{$lg + 100}px)) {
      margin-right: calc((100vw - (#{$lg + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(xl) {
    margin-right: calc((100vw - (#{$xl + 1}px - 53px)) / 2 * -1);
    @media (min-width: calc(#{$xl + 100}px)) {
      margin-right: calc((100vw - (#{$xl + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(xxl) {
    margin-right: calc((100vw - (#{$xxl + 1}px - 53px)) / 2 * -1);
    @media (min-width: calc(#{$xxl + 100}px)) {
      margin-right: calc((100vw - (#{$xxl + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(xxxl) {
    margin-right: calc((100vw - (#{$xxxl + 1}px - 53px)) / 2 * -1);
    @media (min-width: calc(#{$xxxl + 100}px)) {
      margin-right: calc((100vw - (#{$xxxl + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(fhd) {
    margin-right: calc((100vw - (#{$fhd + 1}px - 96px)) / 2 * -1);
    @media (min-width: calc(#{$fhd + 100}px)) {
      margin-right: calc((100vw - (#{$fhd + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(qhd) {
    margin-right: calc((100vw - (#{$qhd + 1}px - 96px)) / 2 * -1);
    @media (min-width: calc(#{$qhd + 100}px)) {
      margin-right: calc((100vw - (#{$qhd + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(kkk) {
    margin-right: calc((100vw - (#{$kkk + 1}px - 128px)) / 2 * -1);
    @media (min-width: calc(#{$kkk + 100}px)) {
      margin-right: calc((100vw - (#{$kkk + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(uwqhd) {
    margin-right: calc((100vw - (#{$uwqhd + 1}px - 128px)) / 2 * -1);
    @media (min-width: calc(#{$uwqhd + 100}px)) {
      margin-right: calc((100vw - (#{$uwqhd + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(kkkk) {
    margin-right: calc((100vw - (#{$kkkk + 1}px - 128px)) / 2 * -1);
    @media (min-width: calc(#{$kkkk + 100}px)) {
      margin-right: calc((100vw - (#{$kkkk + 1}px - 35px)) / 2 * -1);
    }
  }
}

.right-edge-sm-img-wrap {
  margin-right: 0px;

  @include breakpoint-up(sm) {
    margin-right: calc((100vw - (#{$sm + 1}px - 31px)) / 2 * -1);
  }

  @include breakpoint-up(md) {
    margin-right: calc((100vw - (#{$md + 1}px - 31px)) / 2 * -1);
  }

  @include breakpoint-up(lg) {
    margin-right: calc((100vw - (#{$lg + 1}px - 64px)) / 2 * -1);
    @media (min-width: calc(#{$lg + 100}px)) {
      margin-right: calc((100vw - (#{$lg + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(xl) {
    margin-right: calc((100vw - (#{$xl + 1}px - 64px)) / 2 * -1);
    @media (min-width: calc(#{$xl + 100}px)) {
      margin-right: calc((100vw - (#{$xl + 1}px - 35px)) / 2 * -1);
    }
  }

  @include breakpoint-up(xxl) {
    margin-right: calc((100vw - (#{$xxl + 1}px - 64px)) / 2 * -1);
    @media (min-width: calc(#{$xxl + 100}px)) {
      margin-right: calc((100vw - (#{$xxl + 1}px - 37px)) / 2 * -1);
    }
  }

  @include breakpoint-up(xxxl) {
    margin-right: calc((100vw - (#{$xxxl + 1}px - 64px)) / 2 * -1);
    @media (min-width: calc(#{$xxxl + 100}px)) {
      margin-right: calc((100vw - (#{$xxxl + 1}px - 37px)) / 2 * -1);
    }
  }

  @include breakpoint-up(fhd) {
    margin-right: calc((100vw - (#{$fhd + 1}px - 96px)) / 2 * -1);
    @media (min-width: calc(#{$fhd + 100}px)) {
      margin-right: calc((100vw - (#{$fhd + 1}px - 37px)) / 2 * -1);
    }
  }

  @include breakpoint-up(qhd) {
    margin-right: calc((100vw - (#{$qhd + 1}px - 96px)) / 2 * -1);
    @media (min-width: calc(#{$qhd + 100}px)) {
      margin-right: calc((100vw - (#{$qhd + 1}px - 37px)) / 2 * -1);
    }
  }

  @include breakpoint-up(kkk) {
    margin-right: calc((100vw - (#{$kkk + 1}px - 128px)) / 2 * -1);
    @media (min-width: calc(#{$kkk + 100}px)) {
      margin-right: calc((100vw - (#{$kkk + 1}px - 37px)) / 2 * -1);
    }
  }

  @include breakpoint-up(uwqhd) {
    margin-right: calc((100vw - (#{$uwqhd + 1}px - 128px)) / 2 * -1);
    @media (min-width: calc(#{$uwqhd + 100}px)) {
      margin-right: calc((100vw - (#{$uwqhd + 1}px - 37px)) / 2 * -1);
    }
  }

  @include breakpoint-up(kkkk) {
    margin-right: calc((100vw - (#{$kkkk + 1}px - 128px)) / 2 * -1);
    @media (min-width: calc(#{$kkkk + 100}px)) {
      margin-right: calc((100vw - (#{$kkkk + 1}px - 37px)) / 2 * -1);
    }
  }
}

.office-map-wrap {
  @include breakpoint-down(lg) {
    margin-left: calc(-1 * (100vw - #{$md}px) / 2 - 10px);
    margin-right: calc(-1 * (100vw - #{$md}px) / 2 - 10px);
  }
  @include breakpoint-down(md) {
    margin-left: calc(-1 * (100vw - #{$sm}px) / 2 - 10px);
    margin-right: calc(-1 * (100vw - #{$sm}px) / 2 - 10px);
  }
  @include breakpoint-down(sm) {
    margin-left: calc(-1 * (100vw - #{$xs}px) / 2 - 10px);
    margin-right: calc(-1 * (100vw - #{$xs}px) / 2 - 10px);
  }
  @include breakpoint-down(xs) {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.form-sticky {
  position: sticky;
  top: 1rem;
}

// grid alignment for office page

.office-section {
  grid-area: office-section;
  width: 100%;
}

.form-section {
  grid-area: form-section;
}

.about-us-section {
  grid-area: about-us-section;
}

.office-page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'office-section'
    'form-section '
    'about-us-section';

  @include breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
      'office-section office-section office-section office-section office-section office-section form-section  form-section  form-section  form-section  form-section  form-section '
      'about-us-section about-us-section about-us-section about-us-section about-us-section about-us-section form-section  form-section  form-section  form-section  form-section  form-section ';
  }
}

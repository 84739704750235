$font-file-path: '../fonts/Frutiger' !default;
$primary-font-file-path: '../fonts/Inter' !default;

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('#{$primary-font-file-path}/Inter-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('#{$primary-font-file-path}/Inter-LightItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$primary-font-file-path}/Inter-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('#{$primary-font-file-path}/Inter-Italic.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('#{$primary-font-file-path}/Inter-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('#{$primary-font-file-path}/Inter-MediumItalic.woff2')
    format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('#{$primary-font-file-path}/Inter-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('#{$primary-font-file-path}/Inter-SemiBoldItalic.woff2')
    format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('#{$primary-font-file-path}/Inter-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('#{$primary-font-file-path}/Inter-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'frutiger';
  src: url('#{$font-file-path}/frutigerltpro-light-.eot');
  src:
    url('#{$font-file-path}/frutigerltpro-light-.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-file-path}/frutigerltpro-light-.woff2') format('woff2'),
    url('#{$font-file-path}/frutigerltpro-light-.woff') format('woff'),
    url('#{$font-file-path}/frutigerltpro-light-.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'frutiger';
  src: url('#{$font-file-path}/frutigerltpro-roman-.eot');
  src:
    url('#{$font-file-path}/frutigerltpro-roman-.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-file-path}/frutigerltpro-roman-.woff2') format('woff2'),
    url('#{$font-file-path}/frutigerltpro-roman-.woff') format('woff'),
    url('#{$font-file-path}/frutigerltpro-roman-.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'frutiger';
  src: url('#{$font-file-path}/frutigerltpro-lightitalic-.eot');
  src:
    url('#{$font-file-path}/frutigerltpro-lightitalic-.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-file-path}/frutigerltpro-lightitalic-.woff2') format('woff2'),
    url('#{$font-file-path}/frutigerltpro-lightitalic-.woff') format('woff'),
    url('#{$font-file-path}/frutigerltpro-lightitalic-.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'frutiger';
  src: url('#{$font-file-path}/frutigerltpro-italic-.eot');
  src:
    url('#{$font-file-path}/frutigerltpro-italic-.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-file-path}/frutigerltpro-italic-.woff2') format('woff2'),
    url('#{$font-file-path}/frutigerltpro-italic-.woff') format('woff'),
    url('#{$font-file-path}/frutigerltpro-italic-.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'frutiger';
  src: url('#{$font-file-path}/frutigerltpro-bold-.eot');
  src:
    url('#{$font-file-path}/frutigerltpro-bold-.eot?#iefix')
      format('embedded-opentype'),
    url('#{$font-file-path}/frutigerltpro-bold-.woff2') format('woff2'),
    url('#{$font-file-path}/frutigerltpro-bold-.woff') format('woff'),
    url('#{$font-file-path}/frutigerltpro-bold-.ttf') format('truetype');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

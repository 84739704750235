@use '../scss/abstracts' as *;

.news-cards {
  .news-cards-pic {
    height: 180px;
    position: relative;
    width: 100%;
    background-color: var(--ba-gray-50);
    border-radius: 8px;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .news-cards-content {
    @extend %flex-col;

    h4 {
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      display: block;
      height: 60px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0;
    }

    p {
      color: var(--ba-gray-900);
      font-family: var(--font-primary);
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

.ba-slider-cards {
  .ba-slider-cards-pic {
    position: relative;
    width: 100%;
    background-color: var(--ba-gray-50);
    border-radius: 8px;
    aspect-ratio: 16/9;
    overflow: hidden;

    @include breakpoint-down(md) {
      min-height: 168px;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
      margin: auto;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      transition: transform 0.8s;
      will-change: transform;

      &:hover {
        transform: scale(1.1);
        transform-origin: center;
      }
    }
  }

  .ba-slider-cards-content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h4 {
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      display: block;
      height: 60px;
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0;

      @include breakpoint-down(lg) {
        font-size: 18px;
        line-height: 27px;
      }
    }

    p {
      color: var(--ba-gray-900);
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      @include breakpoint-down(lg) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.btn-link {
  @extend %inline-flex-vert-center;
  color: var(--ba-blue-400);
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  gap: 7px;
}

.ba-hover-expand-card {
  min-height: 320px;
  width: 100%;

  @include breakpoint-up(md) {
    min-height: 420px;
  }

  .ba-hover-expand-card-pic {
    position: absolute;
    object-fit: cover;
    z-index: 0;
    overflow: hidden;

    img {
      transform: scale(1);
      transition: transform 0.5s ease-in-out;
      will-change: transform;
    }
  }

  &:hover,
  &:focus-within {
    .ba-hover-expand-card-pic {
      img {
        @include breakpoint-up(md) {
          transform: scale(1.05);
        }
      }
    }

    .ba-hover-expand-card-content {
      opacity: 0;

      &.card-reveal {
        opacity: 1;
      }
    }
  }

  .ba-hover-expand-card-content {
    z-index: 1;
    padding: 24px;
    transition: opacity 0.5s ease;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin-top: 0;
    opacity: 0;

    @include breakpoint-up(md) {
      opacity: 1;
    }

    &.card-reveal {
      opacity: 1;
      z-index: 2;

      @include breakpoint-up(md) {
        opacity: 0;
        z-index: 2;
      }
    }

    &.gradient-top-bottom {
      background: rgb(119, 119, 119);
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.09) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(0, 0, 0, 0.4990371148459384) 100%
      );
    }

    &.gradient-dark {
      background: rgb(119, 119, 119);
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(0, 0, 0, 0.49) 100%
      );
    }
  }
}

.user-card-wrap {
  &:focus,
  &:hover {
    .user-card-img {
      img {
        transform: scale(1.2);
      }
    }
  }

  .user-card-img {
    position: relative;
    z-index: 1;
    margin: 0 30px -30px 0;
    overflow: hidden;
    border-radius: 50%;

    &.centered-img {
      margin: 0 0px -70px 0;
    }

    img {
      transform: scale(1);
      transition: transform 0.5s ease-in-out;
      will-change: transform;
    }
  }

  .user-card-content {
    padding: 80px 22px 59px 22px;

    @include breakpoint-up(md) {
      padding: 80px 22px 32px 22px;
    }

    @include breakpoint-up(lg) {
      padding: 80px 22px 24px 22px;
    }

    background: var(--ba-gray-75, #f7f7f7);
    border-radius: 4px;
  }
}

.info-list-wrapper {
  li {
    padding-left: 24px;
    position: relative;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 6px;
      background: var(--ba-primary-black);
    }
  }
}

.app-promo-video-container {
  min-height: calc(30vh);
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.img-expansion {
  img {
    transform: scale(1) translateZ(1px);
    transition: transform 0.5s ease;
    transform-origin: center center;
    will-change: transform;
  }

  &:hover,
  &:focus-within {
    img {
      transform: scale(1.1);
    }
  }
}

.cut-text-image-description {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.inline-data {
  &:not(:last-child) {
    border-right: solid 1px var(--ba-gray-900);
    padding-right: 8px;
    margin-right: 8px;
  }
}

.shadow-border-white {
  box-shadow: 0 0 6px 2px var(--white);
}

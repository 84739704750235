@use 'abstracts' as *;

html,
body {
  height: 100%;
  overflow-wrap: break-word;

  &::-webkit-scrollbar {
    width: 0.8em;
  }

  &::-webkit-scrollbar-track {
    transition: background-color 1s ease-in-out;
    will-change: background-color;
    background-color: var(--ba-gray-75);
    background-color: linear-gradient(
      90deg,
      rgba(250, 250, 250, 1) 0%,
      rgba(247, 247, 247, 1) 100%
    );
    &:hover {
      background-color: var(--ba-gray-100);
    }
  }

  &::-webkit-scrollbar-thumb {
    width: 0.8em;
    background-color: var(--ba-gray-150);
    box-shadow: 1px 1px 1px var(--ba-gray-100);
    border: solid 1px transparent;
    transition:
      background-color 0.2s ease-in-out,
      outline 0.2s ease-in-out;
    will-change: background-color, outline;

    &:hover {
      background-color: var(--ba-gray-400);
      box-shadow: 1px 0px 3px var(--ba-primary-red);
    }
    &:focus {
      background-color: var(--ba-primary-red);
    }
    &:active {
      background-color: var(--ba-primary-red);
    }
  }
}

body {
  overflow: auto !important;
  padding-right: 0 !important;

  &.alerted {
    #hbl-live-chat-wrapper {
      #olark-wrapper {
        .olark-launch-button-wrapper {
          margin-bottom: 80px;

          @include breakpoint-up(sm) {
            margin-bottom: 70px;
          }
        }
      }
    }
  }
}

@use '../scss/abstracts' as *;

.client-stepper-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 600px;
  height: 600px;
  overflow: hidden;

  @include breakpoint-down(xl) {
    width: 500px;
    height: 500px;
  }
  @include breakpoint-down(lg) {
    width: 400px;
    height: 400px;
  }
  @include breakpoint-down(md) {
    width: 277px;
    height: 277px;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    right: 0px;
    border-radius: 50%;

    @include breakpoint-down(md) {
      right: inherit;
    }
  }
}

.stepper-controls {
  @extend %inline-flex-vert-center;
  gap: 4px;
}

.component-wrapper {
  &.text-card {
    display: inline-flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    width: 100%;
  }

  &.stats-container {
    width: 100%;
  }

  &.content-grid {
    width: 100%;
  }
}

.video-player {
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
  }
}

// close button of InfoWindow inside <GoogleMap>
.gm-style-iw {
  @include breakpoint-up(xs) {
    max-width: 448px !important;
  }

  @include breakpoint-up(sm) {
    max-width: 750px !important;
  }

  @include breakpoint-up(lg) {
    max-width: 370px !important;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }
}

/**
Typography controls are included in this file for the design system followed by bayada.com
The font variables declared below apply for the entire application and compliment the font 
setup in libs/shared/mui/mui-config/src/lib/mui.theme.tsx
**/

@use './breakpoints.module' as *;
@use './palette.module' as *;

$font-primary: 'Inter', sans-serif;
$font-secondary: 'frutiger', sans-serif;
$font-support: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;

$body-font: $font-primary, $font-secondary, $font-support;

:root {
  --font-primary: 'Inter', sans-serif;
  --font-secondary: 'frutiger', sans-serif;

  // Font Steps

  /* 46.00px → 64.00px */
  --t-46-64: clamp(2.875rem, 2.3377rem + 2.6866vw, 4rem);

  /* Step 0: 46px → 54px */
  --t-46-54: clamp(2.875rem, 2.7669rem + 0.5405vi, 3.375rem);

  /* 33.00px → 46.00px */
  --t-33-46: clamp(2.0625rem, 1.6744rem + 1.9403vw, 2.875rem);

  /* 30PX -> 46PX */
  --t-30-46: clamp(1.875rem, 1.3974rem + 2.3881vw, 2.875rem);

  /* 25PX -> 36PX */
  --t-25-36: clamp(1.5625rem, 1.2341rem + 1.6418vw, 2.25rem);

  /* 22.00px → 28.00px */
  --t-22-28: clamp(1.375rem, 1.1959rem + 0.8955vw, 1.75rem);

  /* 18.00px → 21.00px */
  --t-18-21: clamp(1.125rem, 1.0354rem + 0.4478vw, 1.3125rem);

  /* 18.00px → 28.00px */
  --t-18-28: clamp(1.125rem, 1.0354rem + 0.8955vw, 1.75rem);

  /* 16.00px → 17.00px */
  --t-16-17: clamp(1rem, 0.9701rem + 0.1493vw, 1.0625rem);

  /* 14.00px → 14.00px */
  --t-14: clamp(0.875rem, 0.875rem + 0vw, 0.875rem);

  /* 12.00px → 12.00px */
  --t-12: clamp(0.75rem, 0.75rem + 0vw, 0.75rem);

  /* 13.00px → 13.00px */
  --t-13: clamp(0.8125rem, 0.8125rem + 0vw, 0.8125rem);

  /* 18.00px → 19.00px */
  --t-18-19: clamp(1.125rem, 1.0951rem + 0.1493vw, 1.1875rem);

  /* 14.00px → 15.00px */
  --t-14-15: clamp(0.875rem, 0.8451rem + 0.1493vw, 0.9375rem);

  /* 15.00px → 15.00px */
  --t-15: clamp(0.9375rem, 0.9375rem + 0vw, 0.9375rem);

  /* Step 0: 13.00px → 17.00px */
  --t-13-17: clamp(0.8125rem, 0.772rem + 0.2027vw, 1rem);

  /* Step 0: 16.00px → 19.00px */
  --t-16-19: clamp(1rem, 0.9595rem + 0.2027vw, 1.1875rem);
}

$font-weights: (
  light: 300,
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700
);

.t-46-64 {
  font-size: 64px;
  font-size: var(--t-46-64) !important;
  line-height: 1.09;
  letter-spacing: -1.28px;
}

.t-46-54 {
  font-size: 54px;
  font-size: var(--t-46-54) !important;
  line-height: 1.1;
  letter-spacing: -1.08px;
}

.t-33-46 {
  font-size: 46px;
  font-size: var(--t-33-46) !important;
  line-height: 1.130434783;
  letter-spacing: -0.92px;
}

.t-30-46 {
  font-size: 46px;
  font-size: var(--t-30-46) !important;
  line-height: 1.130434783;
  letter-spacing: -0.92px;
}

.t-25-36 {
  font-size: 36px;
  font-size: var(--t-25-36) !important;
  line-height: 1.27;
  letter-spacing: -0.36px;
}

.t-22-28 {
  font-size: 28px;
  font-size: var(--t-22-28) !important;
  line-height: 1.14;
  letter-spacing: -0.36px;
}

.t-18-21 {
  font-size: 21px;
  font-size: var(--t-18-21) !important;
  line-height: 1.23;
}

.t-18-28 {
  font-size: 28px;
  font-size: var(--t-18-28) !important;
  line-height: 1.14;
  letter-spacing: -0.28px;
}

.t-16-17 {
  font-size: 17px;
  font-size: var(--t-16-17) !important;
  line-height: 1.41;
}

.t-14 {
  font-size: 14px;
  font-size: var(--t-14) !important;
  line-height: 1.41;
}

.t-12 {
  font-size: 12px;
  font-size: var(--t-12) !important;
  line-height: 1.41;
}

.t-13 {
  font-size: 13px;
  font-size: var(--t-13) !important;
  line-height: 1.41;
}

.t-18-19 {
  font-size: 19px;
  font-size: var(--t-18-19) !important;
  line-height: 1.41;
}

.t-14-15 {
  font-size: 15px;
  font-size: var(--t-14-15) !important;
}

.t-15 {
  font-size: 15px;
  font-size: var(--t-15) !important;
}

.t-13-17 {
  font-size: 17px;
  font-size: var(--t-13-17) !important;
  line-height: 1.41;
}

.t-16-19 {
  font-size: 19px;
  font-size: var(--t-16-19) !important;
}

.text-shadow {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

@use '../scss/abstracts' as *;
@use 'widgets';
@use 'stepper';
@use 'cards';
@use 'sizing';
@use 'position';
@use '../scss/main.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './override';

* {
  box-sizing: border-box !important;
}

.input-group-cta {
  border-radius: 4px;
  box-shadow:
    0px 4px 8px 0px rgba(0, 0, 0, 0.12),
    0px 4px 44px 0px rgba(0, 0, 0, 0.06);

  &.bg-white {
    background-color: var(--white);
    box-shadow: none;
  }
}
.input-group-with-cta {
  border-radius: 4px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.33);

  @include breakpoint-up(sm) {
    box-shadow:
      0px 4px 10px 0px rgba(0, 0, 0, 0.14),
      0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  }
}
.bg-white {
  background-color: var(--white) !important;
}
.text-primary-shade {
  color: var(--primary-shade) !important;
}
.text-primary-black {
  color: var(--ba-primary-black) !important;
}
.app-nav-wrap {
  top: 0;
  transform: translateY(0%);
  transition: all 0.2s ease-in-out;
  z-index: 99;
  will-change: transfrom;
}
.app-hero-content {
  min-height: calc(50vh);
}

.top-navbar-action-link {
  background: rgba(35, 35, 35, 0.2);
}

.blue-gradient {
  background: linear-gradient(134deg, #d4ebfb 21.29%, #f3faff 92.92%);
}
.ba-tag {
  padding: 3px 8px;
  border-radius: 2px;
  display: inline-block;
  border: 1px solid transparent;

  &.primary {
    background-color: $ba-primary-red;
    color: var(--white);
  }

  &.secondary {
    background-color: var(--black);
    color: var(--white);
  }

  &.outlined {
    color: var(--black);
    border: 1px solid $ba-primary-black;
  }
}
.ba-section-head {
  text-align: center;
  padding-bottom: 56px;

  @include breakpoint-down(lg) {
    padding-bottom: 20px;
  }

  h2 {
    color: var(--ba-primary-black);
    text-align: center;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.13;
    letter-spacing: -1.38px;

    @include breakpoint-down(lg) {
      font-size: 33px;
    }
  }

  p {
    color: var(--ba-gray-900);
    text-align: center;
    font-family: var(--font-primary);
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 16px;

    @include breakpoint-down(lg) {
      font-size: 16px;
    }
  }
}

.review-card-wrap {
  height: 100%;
  flex-grow: 1;
  border: 1px solid var(--ba-gray-75);
  box-shadow: 0 0 6px 2px var(--ba-gray-75);

  .review-card-img {
    z-index: 1;
    position: absolute;
    top: -30px;
    left: 0;
    padding: 0 12px;

    @include breakpoint-up(sm) {
      padding: 0px 28px;
    }
    @include breakpoint-up(lg) {
      padding: 0px 32px;
    }

    figure {
      width: 60px;
      height: 60px;
    }
  }
  .contentful-richtext-group {
    p {
      font-size: var(--t-15);
      line-height: 1.6;
    }
  }
}

.review-card-content {
  height: 100%;

  border-radius: 4px;
  padding: 48px 20px 20px 20px;

  @include breakpoint-up(md) {
    padding: 48px 24px 24px 24px;
  }
  @include breakpoint-up(lg) {
    padding: 48px 32px 36px 32px;
  }
}

.navigation-list {
  &.mobile {
    border-top: solid 1px var(--ba-gray-85);
  }
}

.top-bar-right {
  width: auto;
}

.find-locations-img {
  margin-right: 0px;
  width: 100%;
  @include breakpoint-up(md) {
    width: auto;
  }

  @include breakpoint-up(md) {
    margin-right: calc((100vw - (992px - 31px)) / 2 * -1);
  }
  @include breakpoint-up(lg) {
    margin-right: calc((100vw - (1201px - 31px)) / 2 * -1);
  }
  @include breakpoint-up(xl) {
    margin-right: calc((100vw - (1366px - 31px)) / 2 * -1);
  }
  @include breakpoint-up(xxl) {
    margin-right: calc((100vw - (1536px - 31px)) / 2 * -1);
  }
  @include breakpoint-up(xxxl) {
    margin-right: calc((100vw - (1801px - 31px)) / 2 * -1);
  }

  img {
    width: 100% !important;
    @include breakpoint-up(md) {
      height: 100% !important;
      width: auto !important;
    }
  }
}

// Search dropdown

.gstl_50 {
  &.gssb_c {
    .gssb_e {
      box-shadow: none;

      .gsc-completion-container {
        tr {
          .gssb_a {
            padding: 7px 15px;

            .gsq_a {
              table {
                td {
                  font-family: var(--font-secondary);
                  color: var(--ba-gray-425);
                  font-weight: 400;
                  b {
                    color: var(--ba-primary-black);
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

[hidden] {
  display: none !important;
}

@use '../scss/abstracts' as *;

.hubspot-form-wrapper {
  .submitted-message {
    > p {
      color: var(--ba-primary-black);
      text-align: center;
      font-size: var(--t-30-46);
      font-weight: 400;
      font-family: var(--font-primary);
      padding: 1rem 0px;
    }
  }
}

.hamburger-button {
  display: block;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  opacity: 1;
  border: none;
  width: 24px;
  height: 20px;
  position: relative;
  overflow: hidden;
  transform: scale(1);
  transition: transform 0.1s ease;
  will-change: transform;

  &:hover,
  &:focus {
    outline: none !important;
  }
  &:active {
    transform: scale(1.01);
  }

  .bar {
    pointer-events: none;
  }

  &.open {
    .bar {
      background-color: var(--ba-primary-black);
    }
  }
}

.hamburger-wrap {
  z-index: 999999;
}

.bar {
  position: absolute;
  background-color: var(--ba-primary-black);
  height: 2px;
  transition: 0.2s all ease-in-out;
  will-change: transform;
  transform-origin: center;
  border-radius: 100px;
  left: 0;
  right: 0;

  &.first {
    top: 0;
  }

  &.second {
    top: 9px;
  }

  &.third {
    bottom: 0;
  }
}

.open .first {
  transform: rotate(45deg) translate(2px, 0px);
  transform-origin: left;
  width: 24px;
}

.open .second {
  transform: translateX(-20px);
  opacity: 0;
}

.open .third {
  transform: rotate(-45deg) translate(1px, 1px);
  transform-origin: left;
  width: 24px;
}

.hubspot-wrapper {
  * {
    box-sizing: border-box !important;
  }

  &.hs-form-private {
    flex-grow: 1;

    fieldset {
      max-width: 100%;

      &.form-columns-2 {
        display: grid;
        column-gap: 1rem;

        @include breakpoint-up(lg) {
          column-gap: 1.5rem;
          grid-template-columns: repeat(2, 1fr);
        }

        .input {
          margin-right: 0;
        }

        .hs-form-field {
          width: 100%;
          float: none;
        }
      }

      &.form-columns-1 {
        .input {
          margin-right: 0;
        }
      }

      .hs-richtext {
        &.hs-main-font-element {
          @include breakpoint-up(lg) {
            grid-column: 1/3;
          }
        }
      }
    }

    .hs-richtext {
      margin-bottom: 1.5rem;

      h1 {
        @extend .t-18-21;
        font-weight: 700;
        font-family: var(--font-secondary);
      }
    }
  }

  .hs-fieldtype-text,
  .hs-fieldtype-textarea,
  .hs-form-field,
  .hs-fieldtype-select {
    .input {
      textarea,
      input,
      select {
        max-width: 100%;
        width: 100% !important;
      }
    }
  }

  &.dark-inputs {
    .hs-fieldtype-text,
    .hs-fieldtype-textarea,
    .hs-form-field,
    .hs-fieldtype-select {
      .input {
        textarea,
        input,
        select {
          max-width: 100%;
          width: 100% !important;
          // box-shadow: none;
          --field-theme: var(--ba-gray-100);
        }
      }
    }
  }

  .hs-fieldtype-text,
  .hs-form-field {
    position: relative;
    min-height: 114px;

    > label {
      color: var(--ba-primary-black) !important;
      // position: absolute;
      // top: 18px;
      // left: 16px;
      font-size: var(--t-15) !important;
      font-weight: 400;
      font-family: var(--font-primary) !important;
      line-height: normal;
      transition: transform ease 0.2s;
      will-change: transform;
      transform: scale(1);
      transform-origin: left;
      padding-top: 0;
      width: 100%;
      display: block;
      text-align: left;
      margin-bottom: 5px;

      .hs-form-required {
        margin-left: 2px;
        color: var(--ba-primary-red);
      }
    }

    .input {
      margin-left: 0;
      min-height: 0;

      input {
        --field-theme: var(--white);
        border: solid 1px var(--ba-gray-100);
        border-radius: 4px;
        background: var(--field-theme);
        min-height: 57px !important;
        padding: 14px 16px 14px 16px;
        color: var(--ba-primary-black);
        font-size: var(--t-16-17);
        font-family: var(--font-primary);
        line-height: normal;
        transition: box-shadow 0.5s ease-in-out;
        box-shadow: 0 0 1px 1px var(--ba-gray-150) inset;
        will-change: box-shadow;

        @include breakpoint-up(lg) {
          font-size: var(--t-15);
        }

        &:focus,
        &:hover,
        &:focus-visible {
          box-shadow: 0 0 4px 2px var(--ba-gray-150) inset;
        }
      }
    }

    &.focused,
    &.hs-message,
    &.hs-fieldtype-select {
      > label {
        // transform: translateY(-15px) scale(0.85);
        color: var(--ba-gray-425);
      }
    }

    .hs-error-msgs {
      position: relative;

      li {
        label {
          position: relative;
          top: 0;
          left: 0;
          color: var(--primary-shade);
          font-size: var(--t-13);
          font-family: var(--font-primary);
        }
      }
    }
  }

  .hs-fieldtype-select {
    position: relative;
    margin-bottom: 16px;

    > label {
      // position: absolute;
      // top: 18px;
      // left: 16px;
      font-size: var(--t-15);
      font-family: var(--font-primary);
      line-height: normal;
      transition: transform ease 0.2s;
      will-change: transform;
      transform: scale(1);
      color: var(--ba-primary-black);
      transform-origin: left;

      .hs-form-required {
        color: var(--ba-primary-red);
        margin-left: 2px;
      }
    }

    .input {
      width: 100%;

      > select {
        width: 100%;
        --field-theme: var(--white);
        border: solid 1px var(--ba-gray-100);
        border-radius: 4px;
        background: var(--field-theme);
        min-height: 57px;
        padding: 10px 0px 10px 12px;
        color: var(--ba-primary-black);
        font-size: var(--t-15);
        font-family: var(--font-primary);
        line-height: 1;
        transition: box-shadow 0.5s ease-in-out;
        box-shadow: 0 0 1px 1px var(--ba-gray-150) inset;
        will-change: box-shadow;

        > option {
          font-size: var(--t-15);
          font-family: var(--font-primary);
          line-height: normal;
        }

        &:focus,
        &:hover,
        &:focus-visible {
          box-shadow: 0 0 4px 2px var(--ba-gray-150) inset;
        }
      }
    }

    &.focused {
      > label {
        // transform: translateY(-15px) scale(0.85);
        color: var(--ba-gray-425);
      }

      .input {
        > select {
          font-size: var(--t-15);

          &:focus {
            box-shadow:
              inset 0 1px 3px rgba(0, 0, 0, 0.1),
              0 0 8px rgba(82, 168, 236, 0.6);
          }
        }
      }
    }
  }

  .hs-fieldtype-textarea {
    position: relative;
    min-height: 82px;

    > label {
      color: var(--ba-primary-black);
      // position: absolute;
      // top: 18px;
      // left: 16px;
      font-size: var(--t-15);
      font-family: var(--font-primary);
      line-height: normal;
      transition: transform ease 0.2s;
      will-change: transform;
      transform: scale(1);
      transform-origin: left;
      z-index: 1;

      .hs-form-required {
        margin-left: 2px;
        color: var(--ba-primary-red);
      }
    }

    .input {
      textarea {
        width: 100%;
        --field-theme: var(--white);
        border: solid 1px var(--field-theme);
        border-radius: 4px;
        background: var(--field-theme);
        min-height: 57px;
        padding: 11px 16px 11px 16px;
        color: var(--ba-primary-black);
        font-size: var(--t-15);
        font-family: var(--font-primary);
        line-height: normal;
        box-shadow: 0 0 1px 1px var(--ba-gray-150) inset;

        &:focus,
        &:hover,
        &:focus-visible {
          box-shadow: 0 0 4px 2px var(--ba-gray-150) inset;
        }
      }
    }

    &.focused {
      label {
        // transform: translateY(-15px) scale(0.85);
        color: var(--ba-gray-425);
      }
    }

    .hs-error-msgs {
      position: relative;

      li {
        label {
          position: relative;
          top: 0;
          left: 0;
          color: var(--primary-shade);
          font-size: var(--t-15);
          font-family: var(--font-primary);
        }
      }
    }
  }

  .hs-fieldtype-booleancheckbox {
    .hs-form-booleancheckbox {
      > label {
        cursor: pointer;
        margin: 16px 0px;
        display: block;

        &:hover {
          text-decoration: underline;

          span:before {
            background-color: var(--ba-green-350);
            opacity: 0.7;
          }
        }

        > input {
          display: none;
        }

        > input[type='checkbox'] {
          transform: scale(0);
          font-size: 0;
          width: 0;
          height: 0;
          padding: 0;
          border: none;
        }

        > span {
          position: relative;
          margin-left: 0;
          padding-left: 32px;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            border: solid 2px rgba(35, 35, 35, 0.2);
            border-radius: 50%;
            background-color: transparent;
            opacity: 0.7;
            transition:
              opacity 0.2s ease-in-out,
              background-color 0.1s ease-in-out;
            z-index: 1;
            will-change: opacity, background-color;
          }

          &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 9px;
            bottom: auto;
            right: auto;
            width: 6px;
            height: 12px;
            border: solid 2px white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg) scale(0);
            transition:
              transform ease-in-out 0.5s,
              opacity ease-in-out 0.1s;
            z-index: 2;
            opacity: 0;
            will-change: transform, opacity;
          }
        }

        input[type='checkbox']:checked ~ span:before,
        input[type='checkbox']:focus ~ span:before {
          opacity: 1;
          border-color: var(--ba-green-350);
          background-color: var(--ba-green-350);
        }

        input[type='checkbox']:checked ~ span:after {
          opacity: 1;
          transform: rotate(45deg) scale(1);
        }
      }
    }
  }

  .legal-consent-container {
    margin: 16px 0;
  }

  .hs-submit {
    margin-top: 8px;
    margin-bottom: 24px;

    .actions {
      @include breakpoint-down(xs) {
        padding-right: 0;
      }
      .hs-button {
        cursor: pointer;
        padding: 13px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: solid 1px var(--primary-shade) !important;

        box-shadow: none !important;
        font-family: var(--font-secondary) !important;
        text-shadow: none;
        font-weight: 400;
        background: linear-gradient(
          96deg,
          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 0%,
          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 19.27%,
          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 66.15%,
          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 100%
        );
        color: var(--white);
        font-size: var(--t-15) !important;
        line-height: 1;
        transition: background 0.2s ease-in-out;
        will-change: background;

        &:hover,
        &:focus {
          background: linear-gradient(
            96deg,
            hsl(
                var(--ba-red-h),
                calc(var(--ba-red-s) + 13%),
                calc(var(--ba-red-l) + 5%)
              )
              0%,
            hsl(
                var(--ba-red-h),
                calc(var(--ba-red-s) + 3%),
                calc(var(--ba-red-l) - 3%)
              )
              19.27%,
            hsl(
                var(--ba-red-h),
                calc(var(--ba-red-s) + 3%),
                calc(var(--ba-red-l) - 5%)
              )
              66.15%,
            hsl(
                var(--ba-red-h),
                calc(var(--ba-red-s) + 13%),
                calc(var(--ba-red-l) + 5%)
              )
              100%
          );
        }

        width: 100%;
        @include breakpoint-up(xs) {
          width: auto;
        }
      }
    }
  }

  .hs-dependent-field {
    + * {
      margin-top: 2rem;
    }
  }

  .hs-form-field {
    &.hs-fieldtype-radio {
      margin-bottom: 2rem;

      > label {
        position: static;
        transform: translateY(0) scale(1);
        display: block;
        float: none;
        width: 100%;
        text-align: left;
        margin-bottom: 1rem;
      }

      .inputs-list {
        padding: 0;
      }
    }
  }

  .hs-form-radio {
    line-height: normal;

    + .hs-form-radio {
      margin-top: 1.125rem;
    }

    .hs-form-radio-display {
      display: inline-block;

      > span {
        position: relative;
        padding-left: 1.875rem;
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
        @extend .t-15;
        cursor: pointer;

        &:before {
          content: '';
          width: 1.5rem;
          height: 1.5rem;
          border: rgba($ba-primary-black, 20%) 1px solid;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          transition: border ease 0.2s;
          will-change: border;
        }

        &:after {
          content: '';
          width: 0.875rem;
          height: 0.875rem;
          border-radius: 50%;
          position: absolute;
          top: 0.313rem;
          left: 0.313rem;
          background: var(--ba-green-450);
          transform: scale(0);
          transition: transform ease 0.2s;
          will-change: transform;
        }
      }

      .hs-input {
        display: none;

        &:checked {
          + span {
            &:before {
              border-color: var(--ba-green-450);
            }

            &:after {
              transform: scale(1);
            }
          }
        }

        &:hover {
          + span {
            &:before {
              outline: 1px solid var(--ba-green-450);
            }
          }
        }

        &:focus-visible {
          + span {
            &:before {
              outline: 1px solid var(--ba-green-450);
            }
          }
        }

        &:focus-within {
          + span {
            &:before {
              outline: 1px solid var(--ba-green-450);
            }
          }
        }
      }
    }
  }
}

.loader {
  margin: auto;
  width: 100%;
  max-width: 10em;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 0.5em 0.5em rgba(0, 0, 0, 0.5);
  animation-name: rot;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rot {
  to {
    rotate: 360deg;
  }
}

.overflow-carousel {
  .swiper {
    overflow: visible;

    .swiper-wrapper {
      .swiper-slide {
        height: auto;
        flex-grow: 1;
      }
    }
  }
}

.swiper {
  --swiper-scrollbar-size: 3px;
  --swiper-scrollbar-sides-offset: 0;

  .swiper-scrollbar {
    width: 100%;
    left: 0;

    .swiper-scrollbar-drag {
      top: -9px;
      height: 20px;
      background: transparent;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 5px;
        background: var(--ba-gray-800);
        border-radius: 10px;
        transition: transform ease 0.2s;
        transform-origin: center center;
        will-change: transform;
      }

      &:hover {
        &:before {
          transform: scaleY(1.5);
        }
      }
    }
  }

  &.swiper-horizontal {
    .swiper-scrollbar-horizontal {
      position: static;
      margin-top: 3rem;
    }
  }
}

.social-media-slide {
  .social-media-icon {
    svg {
      transition:
        opactiy ease 0.2s,
        color ease 0.2s;
      opacity: 0.5;
      color: var(--ba-gray-425);
      will-change: opactiy, color;
    }
  }

  .social-media-image {
    transition: transform ease 0.2s;
    transform-origin: center center;
    will-change: transform;
  }

  &:hover {
    .social-media-icon {
      svg {
        opacity: 1;
        color: var(--primary-shade);
      }
    }

    .social-media-image {
      transform: scale(1.05);
    }
  }
}

.case-study-block {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black);
    opacity: 0.1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 60%,
      rgba(0, 0, 0, 1) 100%
    );
    opacity: 0.6;
  }
}

// Style for google map content in dropdown
.pac-container {
  box-shadow: 0px 0px 16px 0px #00000033;
  border-radius: 5px;

  &::after {
    display: none;
  }

  .pac-item {
    border-top: none;
    padding: 12px 20px;

    .pac-icon-marker {
      background-position: 0px 0px;
      background-repeat: no-repeat;
    }

    &.pac-item-selected {
      background-color: var(--ba-gray-100);

      .pac-icon-marker {
        background-position: 0px 0px;
      }
    }
  }

  .pac-icon.pac-icon-marker {
    background-image: url('data:image/svg+xml;base64,PHN2ZyAgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNSAxMS41QzEwLjE1NjkgMTEuNSAxMS41IDEwLjE1NjkgMTEuNSA4LjVDMTEuNSA2Ljg0MzE1IDEwLjE1NjkgNS41IDguNSA1LjVDNi44NDMxNSA1LjUgNS41IDYuODQzMTUgNS41IDguNUM1LjUgMTAuMTU2OSA2Ljg0MzE1IDExLjUgOC41IDExLjVaIiBzdHJva2U9IiM2MDYwNjAiIHN0cm9rZS13aWR0aD0iMS42IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE2IDguNUMxNiAxNS4yNSA4LjUgMjAuNSA4LjUgMjAuNUM4LjUgMjAuNSAxIDE1LjI1IDEgOC41QzEgNi41MTA4OCAxLjc5MDE4IDQuNjAzMjIgMy4xOTY3IDMuMTk2N0M0LjYwMzIyIDEuNzkwMTggNi41MTA4OCAxIDguNSAxQzEwLjQ4OTEgMSAxMi4zOTY4IDEuNzkwMTggMTMuODAzMyAzLjE5NjdDMTUuMjA5OCA0LjYwMzIyIDE2IDYuNTEwODggMTYgOC41WiIgc3Ryb2tlPSIjNjA2MDYwIiBzdHJva2Utd2lkdGg9IjEuNiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
    width: 18px;
    height: 21px;
  }

  .pac-item-query {
    .pac-matched {
      font-weight: 400;
      font-size: 15px;
    }
  }
}

//////////////////////////////////////////////////////////

.page-loader {
  margin: auto;
  border-radius: 50%;
  box-shadow: 0.5em 0.5em rgba(0, 0, 0, 0.5);
  position: relative;
  animation: pageloader 0.8s ease-in-out alternate infinite;
  animation-delay: 0.32s;
  top: -1em;
  width: 20px;
  height: 20px;

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    box-shadow: inherit;
    animation: inherit;
  }

  &:before {
    left: -1em;
    animation-delay: 0.48s;
  }

  &:after {
    right: -1em;
    animation-delay: 0.16s;
  }
}

@keyframes pageloader {
  0% {
    box-shadow: 0 2em 0 -0.2em currentcolor;
  }

  100% {
    box-shadow: 0 1em 0 -0.2em currentcolor;
  }
}

////////////////////////////////////////////

.accordion-content-wrapper {
  + .accordion-content-wrapper {
    margin-top: 16px;
  }
}

//custom scrolbar
.custom-scrollbar {
  &.transparent-scroll {
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border: 1px solid var(--ba-gray-100);
      border-radius: 5px;
    }
  }

  &::-webkit-scrollbar {
    height: 6px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--ba-gray-100);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--ba-gray-200);
    border-radius: 10px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--ba-gray-200);
  }
}

// marquee styling for alert in header
.marquee {
  white-space: nowrap;
  overflow: hidden;
  --alert-timer: 5s;
  --gap: 1rem;
  gap: var(--gap);
  position: relative;
  user-select: none;

  &:hover {
    .scroll-item {
      animation-play-state: paused;
    }
  }

  .scroll-item {
    flex-shrink: 0;
    display: flex;
    gap: var(--gap);
    min-width: 100%;
    animation-delay: 2s;
    animation: scroll var(--alert-timer) linear infinite;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

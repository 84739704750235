@use '../abstracts' as *;

.icon-fill-transparent {
  fill: transparent;
}

.svg-icon {
  display: block;
  pointer-events: none;
  // transition: color $animation-type1 $animation-duration-3;
}

.icon-stroke {
  stroke: currentColor;
}

.icon-fill {
  fill: currentColor;
}

.icon-fill-white,
.icon-stroke-white {
  fill: var(--white);
}

@use './palette.module' as *;
@use './breakpoints.module' as *;

%flex-col {
  display: flex;
  flex-direction: column;
}

%inline-flex-vert-center {
  display: inline-flex;
  align-items: center;
}

// Flex direction (Firefox needs vendor prefix)
@mixin flex-direction($value: row) {
  -moz-flex-direction: $value;
  flex-direction: $value;
}

%touch-tap-none {
  -webkit-tap-highlight-color: transparent;
}

@use 'framework/reset';
@use 'framework/svg-icon';
@use 'fonts';

//Common project changes
@use 'common';

.header {
  background-color: var(--white);
}

@use '../scss/abstracts' as *;

@layer base {
  em {
    font-style: italic;
  }

  :root {
    --color-text-base: var(--ba-primary-black);
    --color-text-muted: var(--ba-gray-900);
    --color-text-inverted: var(--white);
    --color-fill: var(--ba-primary-red);
    --color-cta-primary: var(--white);
    --color-cta-primary-hover: var(--ba-primary-100);
  }

  .blue-theme {
    --ba-primary-red: var(--ba-blue-600);
    --primary-shade: var(--ba-blue-600);
    --ba-red-h: 218.1;
    --ba-red-s: 57.8%;
    --ba-red-v: 21.37%;
  }

  .page-template-wrapper {
    .section {
      &:focus,
      &:focus-visible,
      &:focus-within {
        outline: none;
      }

      scroll-padding: 1rem 0 0 0;
    }
  }

  .content-grid {
    .text-and-image {
      .text-n-image {
        min-height: 3rem;
        min-width: 3rem;

        &.icon-top-text-bottom {
          > img {
            min-height: 6.25rem;
            min-width: 6.25rem;
          }
        }
      }
    }
  }

  video {
    outline-offset: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 100%;
  }

  .cky-footer-shadow {
    background: transparent !important;
    pointer-events: none !important;
  }

  .banner-with-form {
    + .section {
      .component-wrapper {
        .cover {
          padding-top: 2rem;

          @include breakpoint-up(sm) {
            padding-top: 4rem;
          }
        }
      }
    }
  }

  .banner-content {
    .banner-title {
      + .banner-sub-heading {
        margin-top: 1.5rem;
      }

      + .banner-paragraph {
        margin-top: 1rem;
      }

      + .cta-holder {
        margin-top: 1rem;
      }
    }

    .banner-sub-heading {
      + .banner-paragraph {
        margin-top: 1rem;
      }

      + .cta-holder {
        margin-top: 1rem;
      }
    }

    .banner-paragraph {
      + .cta-holder {
        margin-top: 1rem;
      }
    }
  }

  .line-clamp-10 {
    display: -webkit-box;
    line-clamp: 10;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-14 {
    display: -webkit-box;
    line-clamp: 14;
    -webkit-line-clamp: 14;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .component-wrapper {
    &.hubspot-form {
      width: 100%;
    }
    &.video {
      width: 100%;
      height: 100%;
    }
    &.accordion-container {
      width: 100%;
    }
    &.tab-panel {
      width: 100%;
    }
  }

  .section-nav {
    min-width: 0;

    &:hover,
    &:focus {
      .nav-text {
        transform: translateX(0.25rem);
        color: rgba(var(--primary-shade-color), 0.8);
        text-decoration: underline;
      }

      .svg-icon {
        color: rgba(var(--primary-shade-color), 1);
      }
    }

    .nav-text {
      transform: translateX(0);
      color: rgba(var(--primary-shade-color), 1);
      transition:
        transform 0.2s ease-in-out,
        color 0.2s ease-in-out;

      &:has(~ .svg-icon) {
        padding-right: 0.25rem;
      }
    }

    .svg-icon {
      transition: color 0.2s ease-in-out;
      margin-top: 0.125rem;
    }
  }

  .grid-cell {
    > .cta {
      width: 100%;
      @include breakpoint-up(xs) {
        width: auto;
      }

      > div {
        width: 100%;
        @include breakpoint-up(xs) {
          width: auto;
        }
      }
    }
    > .image {
      > * {
        width: 100% !important;
        @include breakpoint-down(xs) {
          width: auto !important;
        }
      }
    }
    > .text-block {
      width: 100%;
    }
  }

  .item-item-head-left,
  .item-item-head-right {
    > .cta {
      width: 100%;
      @include breakpoint-up(xs) {
        width: auto;
      }

      > div {
        width: 100%;
        @include breakpoint-up(xs) {
          width: auto;
        }
      }
    }
    > .tab {
      width: 100%;
    }
    > .hubspot-form {
      width: 100%;
    }
    > .video {
      width: 100%;
      height: 100%;
    }
    > .accordion-container {
      width: 100%;
    }
    > .text-block {
      width: 100%;
    }
  }

  .home-hero {
    min-height: 37rem;

    @include breakpoint-up(lg) {
      min-height: 41.625rem;
    }
    @media screen and (min-width:#{$lg + 1}px) and (max-height: #{$sm}px) {
      min-height: 37.8rem;
    }
    @media screen and (min-width:#{$lg + 1}px) and (max-height: #{$xs}px) {
      min-height: 32rem;
    }
    @include breakpoint-up(xxl) {
      min-height: 75vh;
      min-height: 75svh;
    }
    @include breakpoint-up(fhd) {
      min-height: 90vh;
      min-height: 90svh;
    }
    @media screen and (min-width: #{$fhd + 1}px) and (max-height: #{$xxxl}px) and (min-aspect-ratio: 21/9) {
      min-height: 96svh;
    }
    @include breakpoint-up(qhd) {
      min-height: 92vh;
      min-height: 92svh;
    }
  }
}

@layer utilities {
  .gradient-from-transparent-to-black {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 60%,
      rgba(0, 0, 0, 0.75) 100%
    );
  }
}

.set-frutiger {
  * {
    font-family: var(--font-secondary);
  }
}

/**
 * IMPORTANT
 * 
 * Color variables are included in this file for the design system followed by bayada.com
 * The Color variables declared below apply for the entire application and compliment the
 * setup in the following files:
 * 1. libs/shared/assets/src/lib/scss/abstracts/_palette.module.scss
 * 2. libs/shared/mui/mui-config/src/lib/mui.theme.tsx
 */

@use './palette.module' as *;
@use './utilities' as *;

:root {
  // Colors
  --white: #ffffff;
  --base-margin: 8px;
  --primary-shade: hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l));
  --primary-shade-color: 213, 36, 47;
  --primary-cta-bg: var(--primary-shade);
  --ba-btn-focus-bg: #ae0420;
  --primary-black: 35, 35, 35;

  // Variable generation for colors
  @each $color, $shades in $colors {
    @each $shade, $value in $shades {
      --#{"" + $color}-#{$shade}: #{$value};
    }
  }

  // Generic color
  @each $color, $values in $generic-colors {
    --#{"" + $color}: #{$values};
  }
}

// Background color and text color class generation

@each $color, $shades in $colors {
  @each $shade, $value in $shades {
    .bg-#{'' + $color}-#{$shade} {
      background-color: var(--#{$color}-#{$shade}) !important;
    }
    .bg-#{'' + $color}-#{$shade}-hover {
      &:hover {
        background-color: var(--#{$color}-#{$shade}) !important;
      }
    }
    .color-#{'' + $color}-#{$shade} {
      color: var(--#{$color}-#{$shade}) !important;
    }
    .color-#{'' + $color}-#{$shade}-hover {
      &:hover {
        color: var(--#{$color}-#{$shade}) !important;
      }
    }
    .fill-#{'' + $color}-#{$shade} {
      fill: var(--#{$color}-#{$shade}) !important;
    }
    .border-#{'' + $color}-#{$shade} {
      border-color: var(--#{$color}-#{$shade}) !important;
    }
    .border-#{'' + $color}-#{$shade}-hover {
      &:hover {
        border-color: var(--#{$color}-#{$shade}) !important;
      }
    }
  }
}

.icon-32 {
  width: 32px;
  height: 32px;
}

.icon-24 {
  width: 24px;
  height: 24px;
}

.icon-20 {
  width: 20px;
  height: 20px;
}

.icon-18 {
  width: 18px;
  height: 18px;
}

.icon-16 {
  width: 16px;
  max-height: 16px;
}

.icon-12 {
  width: 12px;
  max-height: 12px;
}

.icon-8 {
  width: 8px;
  max-height: 8px;
}

.min-vh-50 {
  min-height: calc(50vh);
}

.h-auto {
  height: auto !important;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
